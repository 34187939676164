import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Master from '../../layouts/Master'
import Banner from '../../layouts/Banner'

import imgBanner from '../../assets/images/home/bg-home.png'
import plusIcon from '../../assets/images/home/icon-plus.svg'
import stylesIcon from '../../assets/images/home/icon-styles.svg'
import background from '../../assets/images/home/bg-lines-home.svg'
import guidelinesIcon from '../../assets/images/home/icon-guidelines.svg'
import componentsIcon from '../../assets/images/home/icon-components.svg'

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    textAlign: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '85%',
      backgroundPosition: '45% 0%',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0%',
    },
  },
  card: {
    width: 200,
    padding: 20,
    display: 'flex',
    position: 'relative',
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    cursor: 'pointer',
  },
  gridCardText: {
    width: 250,
    display: 'flex',
    position: 'relative',
    margin: 'auto',
  },
  disabled: {
    color: '#ccc',
    cursor: 'not-allowed',
    '& img': {
      opacity: 0.3,
    },
  },
}))

const Home = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Master>
      <>
        <Banner image={imgBanner}>
          <>
            <Box>Design System</Box>
            <Box>Pottencial</Box>
          </>
        </Banner>

        <Container maxWidth="lg">
          <Box className={classes.boxContainer}>
            <Box py={7}>
              <Typography variant="h4">
                Construindo uma identidade visual única
              </Typography>
            </Box>

            <Box m="auto" width={{ lg: '50%' }}>
              <Typography variant="body1">
                Design System trata-se de um conjunto de padrões de design e código para
                manter a consistência visual e funcional de um produto, sistema ou
                organização.
                <br />
                Um sistema de design unificado gera uma melhor experiência,
                é mais facilmente
                <br />
                compreendido pelos usuários e possibilita ser mais
                ágil no desenvolvimento, pois gera uma linguagem comum para trabalhar.
              </Typography>
            </Box>

            <Box mt={12} mb={5} xs={12} px={{ lg: 20 }}>
              <Grid container justifyContent="center" spacing={10}>
                <Grid xs={12} md={4} item>
                  <Card onClick={() => history.push('/guidelines')} className={classes.card}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={guidelinesIcon} alt="Guidelines" />
                        <p>Guidelines</p>
                        <img src={plusIcon} alt="Saiba Mais" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Typography variant="body2">
                      As Guidelines (diretrizes) descreve um processo padronizado, onde
                      estão definidas as regras e condições para construção de um artefato
                      em um ecossistema.
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={4} item>
                  <Card onClick={() => history.push('/styles')} className={classes.card}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={stylesIcon} alt="Styles" />
                        <p className="text">Styles</p>
                        <img src={plusIcon} alt="Saiba Mais" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    O Style (estilos) é um guia para a manter toda a equipe na mesma
                    página, utilizando as mesmas cores, fontes, ícones e outros.
                  </Box>
                </Grid>
                <Grid xs={12} md={4} item>
                  <Card className={`${classes.card} ${classes.disabled}`}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={componentsIcon} alt="Components" />
                        <p className="text">Components</p>
                        <img src={plusIcon} alt="Saiba Mais" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    Os Components (componentes) são a coleção de elementos de interface
                    que podem ser reutilizados dentro dos sistemas Pottencial.
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </>
    </Master>
  )
}

export default Home
