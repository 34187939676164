import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './pages/home'
import Logo from './pages/logo'
import Icons from './pages/icons'
import Email from './pages/email'
import Voice from './pages/voice'
import Styles from './pages/styles'
import Palette from './pages/palette'
import Components from './pages/components'
import Typography from './pages/typography'
import Guidelines from './pages/guidelines'

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>

    <Route exact path="/voice">
      <Voice />
    </Route>

    <Route exact path="/styles">
      <Styles />
    </Route>

    <Route exact path="/typography">
      <Typography />
    </Route>

    <Route exact path="/palette-colours">
      <Palette />
    </Route>

    <Route exact path="/icons">
      <Icons />
    </Route>

    <Route exact path="/logo-pottencial">
      <Logo />
    </Route>

    <Route exact path="/guidelines">
      <Guidelines />
    </Route>

    <Route exact path="/email">
      <Email />
    </Route>

    <Route exact path="/components">
      <Components />
    </Route>

  </Switch>
)

export default Routes
