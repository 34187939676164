import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import icoArrowHeader from '../assets/images/arrowHeader.png'

const useStyles = makeStyles({
  banner: {
    backgroundImage: (props) => `url(${props.image})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& h2': {
      fontWeight: 700,
      color: '#ffffff',
      textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
})

const Banner = ({ children, ...props }) => {
  const classes = useStyles(props)

  return (
    <Box>
      <Box py={18} className={classes.banner}>
        <Container maxWidth="lg">
          <Typography variant="h2">
            {children}
          </Typography>
        </Container>
      </Box>

      <Box
        top={-24}
        height="0px"
        textAlign="center"
        position="relative"
      >
        <img
          alt="Arrow Header"
          src={icoArrowHeader}
        />
      </Box>
    </Box>
  )
}

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default Banner
