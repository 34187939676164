import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import Master from '../../layouts/Master'
import Banner from '../../layouts/Banner'

import logoIcon from '../../assets/images/styles/icon-logo.svg'
import imgBanner from '../../assets/images/styles/bg-styles.png'
import iconsIcon from '../../assets/images/styles/icon-search.svg'
import paletteIcon from '../../assets/images/styles/icon-palette.svg'
import typographyIcon from '../../assets/images/styles/icon-font.svg'
import background from '../../assets/images/styles/bg-lines-styles.svg'

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    textAlign: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '80%',
      backgroundPosition: '53% 0%',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0%',
    },
  },
  card: {
    width: 105,
    height: 105,
    display: 'flex',
    position: 'relative',
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F5F5',
    paddingTop: 15,
    margin: 'auto',
    cursor: 'pointer',
  },
  gridCardText: {
    width: 300,
    position: 'relative',
    margin: 'auto',
  },
  paletteGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
    marginTop: 100,
  },
  gridTypography: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      left: 60,
    },
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  gridIcon: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      right: 65,
    },
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
}))

const Styles = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Master>
      <>
        <Banner image={imgBanner}>
          <Box>Guia de estilos</Box>
        </Banner>

        <Container maxWidth="lg">
          <Box className={classes.boxContainer}>
            <Box pt={10}>
              <Typography variant="h4">Styles</Typography>
            </Box>

            <Box mt={7} mb={11} px={{ lg: 30 }}>
              <Typography variant="body1">
                O style (estilo) é um guia para a manter toda a equipe na mesma página,
                utilizando as mesmas cores, fontes, ícones e outros.
              </Typography>
            </Box>

            <Box px={10}>
              <Grid container spacing={10}>
                <Grid xs={12} md={4} className={classes.gridTypography} item>
                  <Card className={classes.card} onClick={() => history.push('/typography')}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={typographyIcon} alt="Tipografia" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Box mb={2}>
                      <Typography variant="h6">Typography</Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        A typography (tipografia) é a coleção de elementos de interface que
                        podem ser reutilizados dentro dos sistemas Pottencial.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} md={4} item>
                  <Box className={classes.paletteGrid}>
                    <Card className={classes.card} onClick={() => history.push('/palette-colours')}>
                      <CardContent>
                        <Box className="buttonCard">
                          <img src={paletteIcon} alt="Paleta de cores" />
                        </Box>
                      </CardContent>
                    </Card>

                    <Box className={classes.gridCardText}>
                      <Box mb={2}>
                        <Typography variant="h6">Palette Colours</Typography>
                      </Box>

                      <Box>
                        <Typography variant="body2">
                          A Palette Collours (Paleta de cores) são todas as cores necessárias
                          para utilização dentro dos sistemas Pottencial. Respeitando sempre
                          acessibilidade para projetar as cores de forma inclusiva.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} md={4} className={classes.gridIcon} item>
                  <Card className={classes.card} onClick={() => history.push('/icons')}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={iconsIcon} alt="Ícones" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Box mb={2}>
                      <Typography variant="h6">Icons</Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        Os Icons (ícones) são símbolos gráficos, usado para representar
                        determinada função dentro dos sistemas Pottencial.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box xs={12} mt={7} mb={5}>
              <Grid container justifyContent="center" spacing={10}>
                <Grid item>
                  <Card className={classes.card} onClick={() => history.push('/logo-pottencial')}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={logoIcon} alt="Logo Pottencial" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Box mb={2}>
                      <Typography variant="h6">Logo Pottencial</Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        A marca, juntamente com os elementos do sistema de identidade
                        visual, é um dos meios pelos quais uma empresa se comunica com o
                        mundo.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </>
    </Master>
  )
}

export default Styles
