import React from 'react'

import iconFacebook from '../../assets/images/general/Footer/icon-facebook.svg'
import iconInstagram from '../../assets/images/general/Footer/icon-instagram.svg'
import iconLinkedin from '../../assets/images/general/Footer/icon-linkedin.svg'
import iconPhone from '../../assets/images/general/footer-phone.svg'
import iconTwitter from '../../assets/images/general/Footer/icon-twitter.svg'
import logo from '../../assets/images/general/logo.svg'
import '../../assets/style/footer.scss'

const Footer = () => (
  <footer id="main-footer">
    <div className="containerFooter">
      <div className="flex-wrapper">
        <div className="center">
          <div className="center-logo">
            <a
              title="Ir para a página principal"
              aria-label="Ir para a página principal"
              href="/"
            >
              <img src={logo} alt="Logo - Pottencial" />
            </a>
          </div>

          <div className="left-social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/Pottencial"
                  title="Ir para o facebook"
                  aria-label="Ir para o facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconFacebook} alt="Ícone - Facebook" />
                </a>
              </li>

              <li>
                <a
                  href="https://twitter.com/pottencial"
                  title="Ir para o twitter"
                  aria-label="Ir para o twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconTwitter} alt="Ícone - Twitter" />
                </a>
              </li>

              <li>
                <a
                  href="https://instagram.com/pottencialseguradora"
                  title="Ir para o instagram"
                  aria-label="Ir para o instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconInstagram} alt="Ícone - Instagram" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/pottencial-seguradora-s-a"
                  title="Ir para o linkedin"
                  aria-label="Ir para o linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconLinkedin} alt="Ícone - Linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="right">
          <div className="center">
            <div className="center-title">A POTTENCIAL</div>

            <div className="column-list">
              <ul>
                <li>
                  <a
                    title="Ir para a página sobre"
                    aria-label="Ir para a página sobre"
                    href="https://pottencial.com.br/sobre-nos/"
                  >
                    Sobre Nós
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página trabalhe conosco"
                    aria-label="Ir para a página trabalhe conosco"
                    href="https://pottencial.com.br/trabalhe-conosco/"
                  >
                    Trabalhe Conosco
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página de certidões legais"
                    aria-label="Ir para a página de certidões legais"
                    href="https://pottencial.com.br/certidoes-legais/"
                  >
                    Certidões Legais
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página de ouvidoria"
                    aria-label="Ir para a página de ouvidoria"
                    href="https://pottencial.com.br/ouvidoria/"
                  >
                    Ouvidoria
                  </a>
                </li>

                <li>
                  <a
                    href="https://pottencial.com.br/transparencia-e-privacidade/"
                    title="Ir para a página de Transparência e Privacidade"
                    aria-label="Ir para a página de Transparência e Privacidade"
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    Transparência e Privacidade
                  </a>
                </li>

                <li>
                  <a
                    title="Abrir preferências de cookies"
                    aria-label="Abrir preferências de cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ot-sdk-show-settings"
                    href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-cookies-v001.pdf"
                  >
                    Preferências de Cookies
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="center">
            <div className="center-title">RECURSOS</div>

            <div className="column-list">
              <ul>
                <li>
                  <a
                    title="Ir para a página de consultar apólice"
                    aria-label="Ir para a página de consultar apólice"
                    href="https://pottencial.com.br/consultar-apolice/"
                  >
                    Consultar Apólice
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página de blog"
                    aria-label="Ir para a página de blog"
                    href="https://blog.pottencial.com.br/"
                  >
                    Blog
                  </a>
                </li>

                <li>
                  <a
                    href="https://glossario.pottencial.com.br/"
                    title="Ir para a página Glossário de Seguros"
                    aria-label="Ir para a página Glossário de Seguros"
                  >
                    Glossário de Seguros
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página de sinistro"
                    aria-label="Ir para a página de signistro"
                    href="https://pottencial.com.br/sinistro/"
                  >
                    Sinistro
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página Manifesto Ágil"
                    aria-label="Ir para a página Manifesto Ágil"
                    href="https://agiliza.pottencial.com.br/"
                  >
                    Manifesto Ágil
                  </a>
                </li>

                <li>
                  <a
                    title="Ir para a página trabalhe conosco"
                    aria-label="Ir para a página trabalhe conosco"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://developers.pottencial.com.br/api-portal/"
                  >
                    Área do Desenvolvedor
                  </a>
                </li>
                <br />

              </ul>
            </div>
          </div>

          <div className="center">
            <div className="center-title">CANAIS</div>

            <div className="column-list">
              <ul>
                <li>
                  <a
                    title="Ir para a página de contato"
                    aria-label="Ir para a página de contato"
                    href="https://pottencial.com.br/fale-conosco/"
                  >
                    Fale Conosco
                  </a>
                </li>

                <li>
                  <a
                    href="https://ethicspeakup.com.br/pottencial/"
                    title="Ir para a página"
                    aria-label="Ir para a página"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Canal de Denúncias
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="center">
            <div className="center-title">SAC</div>

            <div className="column-list">
              <ul>
                <li>
                  <a
                    href="tel:3121217777"
                    className="link"
                    title="Fale por telefone"
                    aria-label="Fale por telefone"
                  >
                    <div className="icon">
                      <img src={iconPhone} alt="Ícone - Pottencial" />
                      {' '}
                      (31) 2121-7777
                    </div>

                  </a>
                </li>

                <li>
                  <a
                    href="tel:08006067688"
                    className="link"
                    title="Fale por telefone"
                    aria-label="Fale por telefone"
                  >
                    <div className="icon">
                      <img src={iconPhone} alt="Ícone - Pottencial" />
                      {' '}
                      0800 606 7688
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-wrapper">
        <p className="info">
          Pottencial Seguradora S.A. – CNPJ: 11.699.534/0001-74 – SUSEP: 03069
          &nbsp; © Pottencial Seguradora, 2021. Todos os direitos reservados
          <br />
          <br />
          Av. Raja Gabáglia, 1143, 19°andar
          &nbsp; Luxemburgo, Belo Horizonte - MG
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
