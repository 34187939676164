import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import useWindowSize from '../../hooks/useWindowSize'
import logo from '../../assets/images/general/logo.svg'
import logoOrange from '../../assets/images/general/logo-orange.svg'
import iconMenuOrange from '../../assets/images/general/Header/icon-menu-orange.svg'
import iconPessoa from '../../assets/images/menu/icon-pessoa.png'
import iconEmpresa from '../../assets/images/menu/icon-empresa.png'
import iconCorretor from '../../assets/images/menu/icon-corretor.png'
import iconClose from '../../assets/images/general/Header/icon-close.svg'

import '../../assets/style/header.scss'
import '../../assets/style/menu.scss'

const Header = () => {
  const windowSize = useWindowSize()
  const [activeMenu, setActiveMenu] = useState()
  const [menuThree, setMenuThree] = useState(false)

  const menu3 = [
    {
      id: 0,
      title: 'Como Pessoa',
      link: 'https://paravoce.pottencial.com.br/',
      desc: '',
      imgUrl: iconPessoa,
      icon: iconPessoa,
    },
    {
      id: 1,
      title: 'Como Empresa',
      link: 'https://empresas.pottencial.com.br/',
      desc: '',
      imgUrl: iconEmpresa,
      icon: iconEmpresa,
    },
    {
      id: 2,
      title: 'Como Corretor',
      link: 'https://portalcorretor.pottencial.com.br/',
      desc: '',
      imgUrl: iconCorretor,
      icon: iconCorretor,
    },
  ]

  return (
    <header id="new-main-header">
      <div id="mobile-menu" className={activeMenu ? 'active' : ''}>
        <div className="content-menu">
          <div className="logo">
            <Link title="Ir para a página principal" to="/">
              <img src={logo} alt="Logo - Pottencial" />
            </Link>
            <button
              type="button"
              className="close"
              title="Fechar menu"
              aria-label="Fechar menu"
              onClick={() => setActiveMenu(false)}
            >
              <img src={iconClose} alt="Ícone - Pottencial" />
            </button>
          </div>

          <div className="navigation">
            <ul>
              <li>
                <Link
                  title="Ir para a página de blog"
                  to="/guidelines"
                >
                  Guidelines
                </Link>
              </li>
              <li>
                <Link to="/styles">Styles</Link>
              </li>
            </ul>
          </div>
          <div className="login2-navigation">
            <ul>
              <li>
                <button type="button" onClick={() => setMenuThree(!menuThree)}>
                  LOGIN
                </button>
                <ul className={menuThree ? 'dropActiveOne' : ''}>
                  {menu3.map((item, index) => (
                    <li key={`submenu-3-${index}`}>
                      <a
                        title="Ir para a página"
                        aria-label="Ir para a página"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.link}
                      >
                        <img src={item.icon} alt="" />
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="Header menuWhite">
        <div className="Container">
          <div className="MenuLeft">
            <div className="logo">
              <a
                title="Ir para a página principal"
                aria-label="Ir para a página principal"
                rel="noopener noreferrer"
                href="/"
              >
                <img src={logoOrange} alt="Logo - Pottencial" />
              </a>
            </div>

            <div className="MenuLeft__guidelines">
              <ul>
                <li>
                  <Link
                    title="Guidelines"
                    to="/guidelines"
                  >
                    Guidelines
                  </Link>
                </li>
                <li>
                  <Link
                    title="Styles"
                    to="/styles"
                  >
                    Styles
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="MenuRight">
            <a
              className="LinkRight"
              title="Ir para a página Portal do Corretor"
              aria-label="Ir para a página Portal do Corretor"
              target="_blank"
              href="https://portalcorretor.pottencial.com.br/Register"
              rel="noopener noreferrer"
            >
              Seja Nosso Corretor
            </a>

            <ul className="loginMenu">
              <li>
                <div className="buttonLogin">
                  <span>Login</span>
                </div>
                <ul>
                  <li>
                    <div className="LoginDropMenu">
                      <h4>Como deseja fazer login?</h4>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Para Você"
                        aria-label="Ir para a página Para Você"
                        target="_blank"
                        href="https://paravoce.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone pessoa" src={iconPessoa} />
                        Como Pessoa
                      </a>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Portal Empresas"
                        aria-label="Ir para a página Portal Empresas"
                        target="_blank"
                        href="https://empresas.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone Empresa" src={iconEmpresa} />
                        Como Empresa
                      </a>
                      <a
                        className="LinkDropMenu"
                        title="Ir para a página Portal do Corretor"
                        aria-label="Ir para a página Portal do Corretor"
                        target="_blank"
                        href="https://portalcorretor.pottencial.com.br/"
                        rel="noopener noreferrer"
                      >
                        <img alt="Ícone Corretor" src={iconCorretor} />
                        Como Corretor
                      </a>

                      <span className="SignupDropMenu">
                        Ainda não é Pottencial?
                        {' '}
                        <a
                          title="Ir para a página de Cadastro"
                          aria-label="Ir para a página de Cadastro"
                          target="_blank"
                          href="https://paravoce.pottencial.com.br/register"
                          rel="noopener noreferrer"
                        >
                          Cadastre-se
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          {windowSize.width <= 1024 && (
            <button
              type="button"
              className="menu-toggler"
              title="Abrir menu"
              aria-label="Abrir menu"
              onClick={() => setActiveMenu(true)}
            >
              <span>MENU</span>
              <img src={iconMenuOrange} alt="Ícone - Pottencial" />
            </button>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
