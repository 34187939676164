import React from 'react'
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'

import Master from '../../layouts/Master'
import NavFooter from '../../layouts/NavFooter'
import { STYLE_MENU_GUIDELINES } from '../../constants'

import iconTrustworthy from '../../assets/images/voice/icon-trustworthy.svg'
import iconInformative from '../../assets/images/voice/icon-informative.svg'
import iconRespectful from '../../assets/images/voice/icon-respectful.svg'
import iconFriendly from '../../assets/images/voice/icon-friendly.svg'
import iconFormal from '../../assets/images/voice/icon-formal.svg'

const useStyles = makeStyles(() => ({
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
}))

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Guidelines',
    href: '/guidelines',
  },
  {
    text: 'Tom de Voz',
  },
]

const Voice = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU_GUIDELINES]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Typography component="h1" variant="h4">
            Tom de Voz
          </Typography>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              No contexto da comunicação com o público/persona e parceiros, o tom de voz é
              fundamental para a padronização da imagem da empresa. Sua função é transmitir
              nossos princípios e valores dependendo do contexto situacional, gerando mais
              resultados ao conectar com o público e melhorar sua sobre a empresa.
            </Typography>
          </Box>

          <Typography component="h2" variant="h4">
            Guia de Tom
          </Typography>

          <Box className={classes.line} />

          <Typography component="h3" variant="h6">
            Tom de Voz Geral
          </Typography>

          <Box my={4} pr={15}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box textAlign="center">
                  <img src={iconTrustworthy} alt="Confiável" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `<b>Confiável:</b> Use uma linguagem <b>clara</b> e <b>direta</b>
                  . Procure utilizar construções linguísticas mais conhecidas e evite ambiguidades.`,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box my={4} pr={15}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box textAlign="center">
                  <img src={iconInformative} alt="Informativo" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `<b>Informativo:</b> Mantenha uma escrita <b>objetiva</b> e
                    <b>neutra</b>. Evite o excesso de palavras e de interrogação, bem como, não
                    utilize sentenças inteiras em letras maiúsculas.`,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box my={4} pr={15}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box textAlign="center">
                  <img src={iconRespectful} alt="Respeitoso" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `<b>Respeitoso:</b> Utilize expressões de <b>cordialidade</b>
                como: "Por favor", "Por gentileza", "Agradecemos", entre outras, sempre que
                possível, sobretudo nos meios de comunicação direta como mensagens e e-mails.`,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography component="h3" variant="h6">
            Comunicação formal
          </Typography>

          <Box my={4} pr={15}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box textAlign="center">
                  <img src={iconFormal} alt="Comunicação formal" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `Utilizada em canais como e-mails, portais, informativos, documentos 
                  e site institucional. Transmita nosso <b>profissionalismo</b>, ao evitar 
                  coloquialismos. Também atente-se à normal culta vigente..`,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography component="h3" variant="h6">
            Comunicação amigável
          </Typography>

          <Box mt={4} mb={8} pr={15}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box textAlign="center">
                  <img src={iconFriendly} alt="Comunicação amigável" />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: `Presente em e-mails automáticos e informativos, portal do cliente e
                  mensagens. Visando uma melhor <b>fluidez</b> e <b>eficiência</b> da comunicação com o 
                  cliente, inclua alguns elementos de comunicação informal no texto, para que o
                  cliente se sinta acolhido e mais à vontade possível! Apesar disso, evite o uso
                  de palavras controversas.`,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <NavFooter previewLabel="E-mail" previewTo="/email" />
        </Box>
      </Container>
    </Master>
  )
}

export default Voice
