import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'

const NavFooter = ({
  nextLabel, previewLabel, nextTo, previewTo,
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box>
      {previewLabel && (
        <>
          {previewTo && (
            <Button
              to={previewTo}
              variant="text"
              title="Anterior"
              component={Link}
              style={{ display: 'inline' }}
            >
              <Box>{'< Anterior'}</Box>
              <Typography component="div" color="primary">
                <b>{previewLabel}</b>
              </Typography>
            </Button>
          )}

          {!previewTo && (
            <Button
              disabled
              variant="text"
              title="Anterior"
              style={{ display: 'inline' }}
            >
              <Box>{'< Anterior'}</Box>
              <Typography component="div" color="primary">
                <b>{previewLabel}</b>
              </Typography>
            </Button>
          )}
        </>
      )}
    </Box>
    <Box>
      {nextLabel && (
        <>
          {nextTo && (
            <Button
              to={nextTo}
              variant="text"
              title="Próximo"
              component={Link}
              style={{ display: 'inline' }}
            >
              <Box>{'Próximo >'}</Box>
              <Typography component="div" color="primary">
                <b>{nextLabel}</b>
              </Typography>
            </Button>
          )}

          {!nextTo && (
            <Button
              disabled
              variant="text"
              title="Próximo"
              style={{ display: 'inline' }}
            >
              <Box>{'Próximo >'}</Box>
              <Typography component="div" color="primary">
                <b>{nextLabel}</b>
              </Typography>
            </Button>
          )}
        </>
      )}
    </Box>
  </Box>
)

NavFooter.propTypes = {
  nextLabel: PropTypes.string,
  previewLabel: PropTypes.string,
  nextTo: PropTypes.string,
  previewTo: PropTypes.string,
}

NavFooter.defaultProps = {
  nextLabel: '',
  previewLabel: '',
  nextTo: '',
  previewTo: '',
}

export default NavFooter
