export const DEFAULT_THEME = {
  id: 'default',
  primary: '#F26522',
}

export const STYLE_MENU = [
  {
    text: 'Typography',
    href: '/typography',
  },
  {
    text: 'Palette Colours',
    href: '/palette-colours',
  },
  {
    text: 'Icons',
    href: '/icons',
  },
  {
    text: 'Logo Pottencial',
    href: '/logo-pottencial',
  },
]

export const STYLE_MENU_GUIDELINES = [
  {
    text: 'Documents',
  },
  {
    text: 'E-mail',
    href: '/email',
  },
  {
    text: 'Tom de voz',
    href: '/voice',
  },
]

export default { DEFAULT_THEME, STYLE_MENU, STYLE_MENU_GUIDELINES }
