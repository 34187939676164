import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Breadcrumbs, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    '& .MuiBreadcrumbs-separator': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}))

/**
 * Os Breadcrumb (migalhas de pão) ajudam os usuários a entender sua localização atual e oferecem a
 * opção de mover-se entre níveis diferentes.
 *
 * * Sempre coloque-o no topo do conteúdo principal, abaixo do cabeçalho.
 * * Mostre-o apenas quando você estiver um nível na hierarquia e não no nível mais alto.
 * * Os títulos na localização atual devem corresponder aos rótulos na navegação e no cabeçalho
 * principal `"<h1>"` da página.
 * * A página selecionada deve sempre estar em **negrito**.
 *
 * ```jsx
 * const Path = [
 *   {
 *      text: 'Espaço Corretor',
 *      href: '/',
 *   },
 *   {
 *      text: 'Cadastro',
 *      href: '',
 *   },
 * ]
 *
 * const Test = () => <Breadcrumb paths={Path} />
 * ```
 */
const Breadcrumb = ({ paths }) => {
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {
        paths.map((path, index) => (
          <span key={index}>
            {index !== paths.length - 1 && (
              <Link className={classes.link} to={path.href}>{path.text}</Link>
            )}
            {index === paths.length - 1 && (
              <Typography variant="body2" color="textPrimary">
                <Box component="span" fontWeight={600}>{path.text}</Box>
              </Typography>
            )}
          </span>
        ))
      }

    </Breadcrumbs>
  )
}

Breadcrumb.propTypes = {
  /**
   * Representação hierárquica dos níveis da localização do usuário.
   * <br>
   * <p>Contrato:</p>
   * `[{ text: '', href: '' }]`
   */
  paths: PropTypes.array.isRequired,
}

export default Breadcrumb
