import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/core'
import { Router, useLocation } from 'react-router-dom'

import Routes from './Routes'
import { createTheme } from './theme'
import { DEFAULT_THEME } from './constants'

import './assets/style/global.scss'

const history = createBrowserHistory()

const ScrollTop = () => {
  const { pathname } = useLocation()

  /**
   * Solution to position the scroll in the
   * initial position when performing navigation.
   */
  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0)
  }, [pathname])

  return <></>
}

const App = () => {
  const theme = createTheme(DEFAULT_THEME)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ScrollTop />
          <Routes />
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
