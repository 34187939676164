import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

import Master from '../../layouts/Master'
import { STYLE_MENU } from '../../constants'
import NavFooter from '../../layouts/NavFooter'

import imgExample01 from '../../assets/images/icons/img-example-01.svg'
import iconGarantia from '../../assets/images/icons/icon-garantia.svg'
import iconGarantiaJudicial from '../../assets/images/icons/icon-garantia-judicial.svg'
import iconDepositoRecursal from '../../assets/images/icons/icon-deposito-recursal.svg'
import iconRiscosEngenharia from '../../assets/images/icons/icon-riscos-engenharia.svg'
import iconPottencialAluguel from '../../assets/images/icons/icon-pottencial-aluguel.svg'
import iconGarantiaLicitante from '../../assets/images/icons/icon-garantia-licitante.svg'
import iconPottencialIncendio from '../../assets/images/icons/icon-pottencial-incendio.svg'
import iconMaquinasEquipamentos from '../../assets/images/icons/icon-maquinas-equipamentos.svg'
import iconPottencialPerformance from '../../assets/images/icons/icon-pottencial-performance.svg'

import iconLuz from '../../assets/images/icons/icon-luz.svg'
import iconGas from '../../assets/images/icons/icon-gas.svg'
import iconIptu from '../../assets/images/icons/icon-iptu.svg'
import iconMulta from '../../assets/images/icons/icon-multa.svg'
import iconAluguel from '../../assets/images/icons/icon-aluguel.svg'
import iconCondominio from '../../assets/images/icons/icon-condominio.svg'
import iconDanosImovel from '../../assets/images/icons/icon-danos-imovel.svg'
import iconFundoPromocao from '../../assets/images/icons/icon-fundo-promocao.svg'
import iconPinturaExterna from '../../assets/images/icons/icon-pintura-externa.svg'
import iconPinturaInterna from '../../assets/images/icons/icon-pintura-interna.svg'
import iconArCondicionado from '../../assets/images/icons/icon-ar-condicionado.svg'

import iconVendaval from '../../assets/images/icons/icon-vendaval.svg'
import iconConteudo from '../../assets/images/icons/icon-conteudos.svg'
import iconCoberturaBasico from '../../assets/images/icons/icon-cobertura-basica.svg'
import iconDanosEletricos from '../../assets/images/icons/icon-danos-eletronicos.svg'
import iconImpactoVeiculos from '../../assets/images/icons/icon-impacto-veiculos-terrestres.svg'
import iconPerdaPagamentoAluguel from '../../assets/images/icons/icon-perda-pagamento-aluguel.svg'

import iconPdf from '../../assets/images/icons/outiline/icon-pdf.svg'
import iconCcg from '../../assets/images/icons/outiline/icon-ccg.svg'
import iconTempo from '../../assets/images/icons/outiline/icon-tempo.svg'
import iconSenha from '../../assets/images/icons/outiline/icon-senha.svg'
import iconBuscar from '../../assets/images/icons/outiline/icon-busca.svg'
import iconOnOff from '../../assets/images/icons/outiline/icon-on-off.svg'
import iconExecel from '../../assets/images/icons/outiline/icon-excel.svg'
import iconAgenda from '../../assets/images/icons/outiline/icon-agenda.svg'
import iconPainel from '../../assets/images/icons/outiline/icon-painel.svg'
import iconAvatar from '../../assets/images/icons/outiline/icon-avatar.svg'
import iconFiltro from '../../assets/images/icons/outiline/icon-filtro.svg'
import iconEnviar from '../../assets/images/icons/outiline/icon-enviar.svg'
import iconEditar from '../../assets/images/icons/outiline/icon-editar.svg'
import iconImoveis from '../../assets/images/icons/outiline/icon-imovel.svg'
import iconUsuario from '../../assets/images/icons/outiline/icon-usuario.svg'
import iconCliente from '../../assets/images/icons/outiline/icon-cliente.svg'
import iconFaturas from '../../assets/images/icons/outiline/icon-faturas.svg'
import iconApolice from '../../assets/images/icons/outiline/icon-apolice.svg'
import iconDemandas from '../../assets/images/icons/outiline/icon-demandas.svg'
import iconExportar from '../../assets/images/icons/outiline/icon-exportar.svg'
import iconDownload from '../../assets/images/icons/outiline/icon-download.svg'
import iconSinistro from '../../assets/images/icons/outiline/icon-sinistro.svg'
import iconImprimir from '../../assets/images/icons/outiline/icon-impressora.svg'
import iconBoleto from '../../assets/images/icons/outiline/icon-codigo-barra.svg'
import iconProfissao from '../../assets/images/icons/outiline/icon-profissao.svg'
import iconCalendario from '../../assets/images/icons/outiline/icon-calendario.svg'
import iconFinanceiro from '../../assets/images/icons/outiline/icon-financeiro.svg'
import iconLimiteTaxa from '../../assets/images/icons/outiline/icon-limite-taxa.svg'
import iconAtendimento from '../../assets/images/icons/outiline/icon-atendimento.svg'
import iconNovaDemanda from '../../assets/images/icons/outiline/icon-nova-demanda.svg'
import iconInterrogacao from '../../assets/images/icons/outiline/icon-interrogacao.svg'
import iconEmailSucesso from '../../assets/images/icons/outiline/icon-email-sucesso.svg'
import iconDanosAoImovel from '../../assets/images/icons/outiline/icon-danos-imovel.svg'
import iconExpandirCima from '../../assets/images/icons/outiline/icon-expandir-cima.svg'
import iconSlideDireita from '../../assets/images/icons/outiline/icon-slide-direita.svg'
import iconOportunidade from '../../assets/images/icons/outiline/icon-oportunidades.svg'
import iconDemaNegadas from '../../assets/images/icons/outiline/icon-demandas-negadas.svg'
import iconSlideEsquerda from '../../assets/images/icons/outiline/icon-slide-esquerda.svg'
import iconExpandirBaixo from '../../assets/images/icons/outiline/icon-expandir-baixo.svg'
import iconDemandasAndamento from '../../assets/images/icons/outiline/icon-demandas-andamento.svg'
import iconProducaoCorretora from '../../assets/images/icons/outiline/icon-producao-corretora.svg'
import iconProdutosPottencial from '../../assets/images/icons/outiline/icon-produto-pottencial.svg'
import iconConfigurarAreaCliente from '../../assets/images/icons/outiline/icon-configurar-area-cliente.svg'

import iconAvaliar from '../../assets/images/icons/filled/icon-avaliar.svg'
import iconAtencao from '../../assets/images/icons/filled/icon-atencao.svg'
import iconRemover from '../../assets/images/icons/filled/icon-remover.svg'
import iconPesquisar from '../../assets/images/icons/filled/icon-pesquisa.svg'
import iconAdicionar from '../../assets/images/icons/filled/icon-adicionar.svg'
import iconNotificacao from '../../assets/images/icons/filled/icon-notificacao.svg'

const useStyles = makeStyles(() => ({
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  img: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
  },
  xdButtom: {
    width: 200,
    height: 60,
    borderRadius: 5,
    boxShadow: '0 0',
    border: '1px solid #e4e4e4',
  },
  xdImage: {
    width: '35%',
    float: 'left',
    display: 'flex',
    position: 'relative',
    bottom: 5,
    background: '#919191',
  },
  xdText: {
    position: 'relative',
    left: 12,
    bottom: 5,
  },
  gridCard: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCardText: {
    fontSize: 12,
  },
  imgIcon: {
    maxWidth: 60,
  },
  iconImage: {
    height: 50,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}))

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Style',
    href: '/styles',
  },
  {
    text: 'Icons',
  },
]

const createIcon = (descricao, icon1, icon2) => ({
  descricao, icon1, icon2,
})

const IconesPottencialLine1 = [
  createIcon('Garantia', iconGarantia, null),
  createIcon('Garantia Judicial', iconGarantiaJudicial, null),
  createIcon('Depósito Recursal', iconDepositoRecursal, null),
  createIcon('Pottencial Performance', iconPottencialPerformance, null),
  createIcon('Garantia do Licitante (BID)', iconGarantiaLicitante, null),
]

const IconesPottencialLine2 = [
  createIcon('Riscos de Engenharia', iconRiscosEngenharia, null),
  createIcon('Máquinas e Equipamentos', iconMaquinasEquipamentos, null),
  createIcon('Pottencial Aluguel', iconPottencialAluguel, null),
  createIcon('Pottencial Incêndio', iconPottencialIncendio, null),
]

const PottencialAluguel = [
  createIcon('Aluguel', iconAluguel, null),
  createIcon('Luz', iconLuz, null),
  createIcon('IPTU', iconIptu, null),
  createIcon('Condomínio', iconCondominio, null),
  createIcon('Danos ao imóvel', iconDanosImovel, null),
  createIcon('Gás', iconGas, null),
  createIcon('Pintura externa', iconPinturaExterna, null),
  createIcon('Pintura Interna', iconPinturaInterna, null),
  createIcon('Fundo de Promoção', iconFundoPromocao, null),
  createIcon('Ar Condicionado', iconArCondicionado, null),
  createIcon('Multa por rescisão contratual', iconMulta, null),
]

const PottencialIncendio = [
  createIcon('Cobertura básica', iconCoberturaBasico, null),
  createIcon('Perda ou pagamento de aluguel', iconPerdaPagamentoAluguel, null),
  createIcon('Danos elétricos', iconDanosEletricos, null),
  createIcon('Impacto de veículos terrestres', iconImpactoVeiculos, null),
  createIcon('Vendaval, furacão, ciclone, tornado e granizo', iconVendaval, null),
  createIcon('Conteúdos', iconConteudo, null),
]

const IconesOutiline = [
  createIcon('Painel', iconPainel, null),
  createIcon('Oportunidades', iconOportunidade, null),
  createIcon('Demandas', iconDemandas, null),
  createIcon('Apólices', iconApolice, null),
  createIcon('PDF', iconPdf, null),
  createIcon('Execel', iconExecel, null),
  createIcon('Nova demanda', iconNovaDemanda, null),
  createIcon('Demandas Negadas', iconDemaNegadas, null),
  createIcon('Demandas em Andamento', iconDemandasAndamento, null),
  createIcon('Faturas', iconFaturas, null),
  createIcon('CCG', iconCcg, null),
  createIcon('Produtos Pottencial', iconProdutosPottencial, null),
  createIcon('Exportar', iconExportar, null),
  createIcon('Download', iconDownload, null),
  createIcon('Ativar/Desativar', iconOnOff, null),
  createIcon('Buscar', iconBuscar, null),
  createIcon('Agenda', iconAgenda, null),
  createIcon('Calendário', iconCalendario, null),
  createIcon('Tempo', iconTempo, null),
  createIcon('Avatar', iconAvatar, null),
  createIcon('Usuário', iconUsuario, null),
  createIcon('Cliente', iconCliente, null),
  createIcon('Configurar área do Cliente', iconConfigurarAreaCliente, null),
  createIcon('Limite de Taxa', iconLimiteTaxa, null),
  createIcon('Produção da Corretora', iconProducaoCorretora, null),
  createIcon('E-mail de Senha', iconSenha, null),
  createIcon('E-mail de Sucesso', iconEmailSucesso, null),
  createIcon('Imprimir', iconImprimir, null),
  createIcon('Financeiro', iconFinanceiro, null),
  createIcon('Boleto/código De barras', iconBoleto, null),
  createIcon('Interrogação', iconInterrogacao, null),
  createIcon('Filtro', iconFiltro, null),
  createIcon('Danos ao imóvel', iconDanosAoImovel, null),
  createIcon('Profissão', iconProfissao, null),
  createIcon('Imóveis', iconImoveis, null),
  createIcon('Sinistro', iconSinistro, null),
  createIcon('Atendimento', iconAtendimento, null),
  createIcon('Enviar', iconEnviar, null),
  createIcon('Slide', iconSlideDireita, iconSlideEsquerda),
  createIcon('Expandir e recolher', iconExpandirCima, iconExpandirBaixo),
  createIcon('Editar', iconEditar, null),
]

const IconesFilled = [
  createIcon('Adicionar', iconAdicionar, null),
  createIcon('Pesquisar', iconPesquisar, null),
  createIcon('Atenção', iconAtencao, null),
  createIcon('Notificação', iconNotificacao, null),
  createIcon('Avaliar', iconAvaliar, null),
  createIcon('Remover', iconRemover, null),
]

const IconItem = ({ item }) => {
  const classes = useStyles()

  return (
    <Box className={classes.gridCard} title={item.descricao}>
      <Box className={classes.iconImage}>

        {item.icon1 && (
          <PopupState variant="popover">
            {(popupState) => (
              <>
                <img
                  src={item.icon1}
                  alt={item.descricao}
                  className={classes.imgIcon}
                  {...bindTrigger(popupState)}
                />

                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    component="a"
                    href={item.icon1}
                    onClick={popupState.close}
                    download={`${item.descricao} 1`}
                  >
                    Download
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        )}

        {item.icon2 && (
          <PopupState variant="popover">
            {(popupState) => (
              <>
                <img
                  src={item.icon2}
                  alt={item.descricao}
                  className={classes.imgIcon}
                  {...bindTrigger(popupState)}
                />

                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    component="a"
                    href={item.icon2}
                    onClick={popupState.close}
                    download={`${item.descricao} 2`}
                  >
                    Download
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        )}
      </Box>
      <Box>
        <Typography className={classes.gridCardText} variant="body2">
          {item.descricao}
        </Typography>
      </Box>
    </Box>
  )
}

IconItem.propTypes = {
  item: PropTypes.object.isRequired,
}

const Icons = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Box>
            <Typography variant="h4">Icons</Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              Os ícones são uma representação gráfica simples de uma determinada função.
              Ele deve ser sempre claro em seu significado.
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="h5">
              Ícones que representam os Produtos Pottencial
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              Para a representação dos produtos da Pottencial é utilizado os ícones
              a baixo. Em determinadas situações como no site e landpage não é
              necessariamente preciso utilizar o background. Porém dentro dos
              sistemas em sua maioria já utilizado com o background.
            </Typography>
          </Box>

          <Grid container justifyContent="center" style={{ margin: -5 }} spacing={10}>
            {IconesPottencialLine1.map((pottencialLine1, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={pottencialLine1} />
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent="center" style={{ margin: -5 }} spacing={10}>
            {IconesPottencialLine2.map((pottencialLine2, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={pottencialLine2} />
              </Grid>
            ))}
          </Grid>

          <Box mb={5}>
            <Typography variant="h5">
              Ícones das coberturas
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              Cada produto possui suas particularidades e dentre elas estão as coberturas.
              Para representar as coberturas dos produtos segue a baixo:
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="h5">
              Potencial Aluguel:
            </Typography>
          </Box>

          <Grid container justifyContent="center" style={{ margin: -5, justifyContent: 'left' }} spacing={10}>
            {PottencialAluguel.map((aluguel, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={aluguel} />
              </Grid>
            ))}
          </Grid>

          <Box mb={5}>
            <Typography variant="h5">
              Potencial Incêndio:
            </Typography>
          </Box>

          <Grid container justifyContent="center" style={{ margin: -5, justifyContent: 'left' }} spacing={10}>
            {PottencialIncendio.map((incendio, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={incendio} />
              </Grid>
            ))}
          </Grid>

          <Box mb={5}>
            <Typography variant="h5">
              Ícones utilizados nos sistemas Pottencial - Outiline
            </Typography>
          </Box>

          <Grid container justifyContent="center" style={{ margin: -5, justifyContent: 'left' }} spacing={10}>
            {IconesOutiline.map((outiline, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={outiline} />
              </Grid>
            ))}
          </Grid>

          <Box mb={5}>
            <Typography variant="h5">
              Ícones utilizados nos sistemas Pottencial - Filled
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              A maioria do ícones utilizados nos sistemas Potencial é outiline (contorno),
              sem preenchimento. Porém em algumas situações é necessário a utilização dos
              ícones filled (com preenchimento). Avalie a necessidade diante de cada situação.
            </Typography>
          </Box>

          <Grid container justifyContent="center" style={{ margin: -5, justifyContent: 'left' }} spacing={10}>
            {IconesFilled.map((filled, index) => (
              <Grid xs={12} md={2} key={index} item>
                <IconItem item={filled} />
              </Grid>
            ))}
          </Grid>

          <Box>
            <Typography variant="h4">
              Exemplo de aplicação
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              Ícones de Produtos com backgound:
            </Typography>
          </Box>

          <Box mb={5}>
            <img className={classes.img} src={imgExample01} alt="Ícones de Produtos com backgound" />
          </Box>

          <Divider />

          <NavFooter
            previewLabel="Palette Colours"
            previewTo="/palette-colours"
            nextLabel="Logo Pottencial"
            nextTo="/logo-pottencial"
          />
        </Box>
      </Container>
    </Master>
  )
}

export default Icons
