import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'

import Master from '../../layouts/Master'
import { STYLE_MENU } from '../../constants'
import NavFooter from '../../layouts/NavFooter'

import imgExample01 from '../../assets/images/palette/img-example-01.png'
import imgExample02 from '../../assets/images/palette/img-example-02.png'
import imgExample03 from '../../assets/images/palette/img-example-03.png'

const useStyles = makeStyles({
  container: {
    margin: 0,
    width: '100%',
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  table: {
    '& td': {
      fontWeight: 100,
      fontSize: 'small',
      borderBottom: 0,
      color: 'white',
    },
  },
  icon: {
    float: 'right',
    width: 15,
    cursor: 'pointer',
  },
  iconText: {
    float: 'left',
    fontSize: 12,
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10,
  },
})

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Style',
    href: '/styles',
  },
  {
    text: 'Palette Colours',
  },
]

const Palette = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Box>
            <Typography variant="h4">
              Palette Colours
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              A paleta de cores são compostas por cores para que possam ter harmonia e
              assim possam ser usadas juntas. Gerando personalidade aos sistemas da Pottencial,
              garantindo uma identidade visual única. As cores se dividem em primária, que é a
              cor exibida com mais frequência nas telas e componentes do sistema e as
              secundárias claras e escuras que podem ser usadas para ícones, fontes
              e outros. Cada cor possui 3 variações de tons, para serem utilizadas em
              interfaces que possam demandar uma maior complexidade.
            </Typography>
          </Box>

          <Box>
            <Grid container justifyContent="center" className={classes.container} spacing={10}>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>LARANJA POTTENCIAL</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#F26522', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#F26522</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#F26522') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#F47B42' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#F47B42</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#F47B42') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#F69262' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#F69262</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#F69262') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#F8A882' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#F8A882</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#F8A882') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>CINZA ESCURO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#3C3C3C', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#3C3C3C</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#3C3C3C') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#595959' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#595959</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#595959') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#7B7B7B' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#7B7B7B</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#7B7B7B') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#B3B3B3' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#B3B3B3</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#B3B3B3') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>CINZA CHUMBO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#808080', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#808080</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#808080') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#919191' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#919191</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#919191') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#BEBEBE' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#BEBEBE</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#BEBEBE') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#F5F5F5' }}>
                        <TableCell>
                          <Typography style={{ color: 'black' }} className={classes.iconText}>#F5F5F5</Typography>
                          <LibraryBooksIcon style={{ color: 'black' }} className={classes.icon} onClick={() => { navigator.clipboard.writeText('#F5F5F5') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>CINZA CHUMBO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#959BA5', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#959BA5</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#959BA5') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#A4A9B2' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#A4A9B2</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#A4A9B2') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#B3B7BF' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#B3B7BF</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#B3B7BF') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#D2D4D8' }}>
                        <TableCell>
                          <Typography style={{ color: 'black' }} className={classes.iconText}>#D2D4D8</Typography>
                          <LibraryBooksIcon style={{ color: 'black' }} className={classes.icon} onClick={() => { navigator.clipboard.writeText('#D2D4D8') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>ROXO POTTENCIAL 10 ANOS</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#5D20AE', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#5D20AE</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#5D20AE') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#7E38DA' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#7E38DA</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#7E38DA') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#9054E0' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#9054E0</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#9054E0') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#A371E5' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#A371E5</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#A371E5') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>AZUL NOITE</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#2D3E5B', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#2D3E5B</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#2D3E5B') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#4C5A73' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#4C5A73</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#4C5A73') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#6B778C' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#6B778C</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#6B778C') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#AAB0BC' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#AAB0BC</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#AAB0BC') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>AZUL CÉU</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#A1CCD4', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#A1CCD4</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#A1CCD4') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#BCDBE1' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#BCDBE1</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#BCDBE1') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#CAE2E6' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#CAE2E6</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#CAE2E6') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#D8EAED' }}>
                        <TableCell>
                          <Typography style={{ color: 'black' }} className={classes.iconText}>#D8EAED</Typography>
                          <LibraryBooksIcon style={{ color: 'black' }} className={classes.icon} onClick={() => { navigator.clipboard.writeText('#D8EAED') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={3} item>
                <Typography className={classes.titleBox}>VERDE ALGA</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#97B5B1', height: 100 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#97B5B1</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#97B5B1') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#B6CBC8' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#B6CBC8</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#B6CBC8') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#C5D5D3' }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#C5D5D3</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#C5D5D3') }} />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ backgroundColor: '#D4DFDE' }}>
                        <TableCell>
                          <Typography style={{ color: 'black' }} className={classes.iconText}>#D4DFDE</Typography>
                          <LibraryBooksIcon style={{ color: 'black' }} className={classes.icon} onClick={() => { navigator.clipboard.writeText('#D4DFDE') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>

          <Box mb={5} mt={5}>
            <Typography variant="h5">
              Cores de alerta
            </Typography>
          </Box>

          <Box>
            <Grid container justifyContent="center" className={classes.container} spacing={10}>

              <Grid xs={12} md={4} item>
                <Typography className={classes.titleBox}>VERMELHO PERIGO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#C3284C', height: 150 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#C3284C</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#C3284C') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={4} item>
                <Typography className={classes.titleBox}>AMARELO ATENÇÃO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#FFB500', height: 150 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#FFB500</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#FFB500') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} md={4} item>
                <Typography className={classes.titleBox}>VERDE SUCESSO</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow style={{ backgroundColor: '#439508', height: 150 }}>
                        <TableCell>
                          <Typography className={classes.iconText}>#439508</Typography>
                          <LibraryBooksIcon className={classes.icon} onClick={() => { navigator.clipboard.writeText('#439508') }} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="h4">
              Exemplo de aplicação
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography style={{ marginBottom: 15 }} variant="h5">
              Laranja Pottencial #F26522
            </Typography>
            <Typography variant="body1">
              Alguns exemplos de utilização do laranja Pottencial,
              são nos cabeçalhos, rodapé, links, botões, ícones, como segue nos exemplos:
            </Typography>
          </Box>

          <Box mb={4}>
            <Grid container>
              <Grid item>
                <img width={280} src={imgExample01} alt="Exemplo de aplicação" />
              </Grid>
              <Grid item>
                <img width={267} src={imgExample02} alt="Exemplo de aplicação" />
              </Grid>
              <Grid item>
                <img width={293} src={imgExample03} alt="Exemplo de aplicação" />
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <NavFooter
            previewLabel="Typography"
            previewTo="/typography"
            nextLabel="Icons"
            nextTo="/icons"
          />
        </Box>
      </Container>
    </Master>
  )
}

export default Palette
