import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import Master from '../../layouts/Master'
import Banner from '../../layouts/Banner'

import imgBanner from '../../assets/images/components/bg-components.png'
import background from '../../assets/images/components/bg-lines-components.svg'
import iconAlertas from '../../assets/images/components/icon-alerta.svg'
import iconAutoComplete from '../../assets/images/components/icon-autocomplete.svg'
import iconBreadcrumbs from '../../assets/images/components/icon-breadcrumbs.svg'
import iconButton from '../../assets/images/components/icon-button.svg'
import iconCard from '../../assets/images/components/icon-card.svg'
import iconCollapse from '../../assets/images/components/icon-collapse.svg'
import iconDatepicker from '../../assets/images/components/icon-datepicker.svg'
import iconFilter from '../../assets/images/components/icon-filter.svg'
import iconFooter from '../../assets/images/components/icon-footer.svg'
import iconForm from '../../assets/images/components/icon-form.svg'
import iconHeader from '../../assets/images/components/icon-header.svg'
import iconLink from '../../assets/images/components/icon-link.svg'
import iconLogin from '../../assets/images/components/icon-login.svg'
import iconModal from '../../assets/images/components/icon-modal.svg'
import iconPagination from '../../assets/images/components/icon-pagination.svg'
import iconPassword from '../../assets/images/components/icon-password.svg'
import iconProgress from '../../assets/images/components/icon-progress.svg'
import iconSearch from '../../assets/images/components/icon-search.svg'
import iconSelection from '../../assets/images/components/icon-selection.svg'
import iconStepp from '../../assets/images/components/icon-stepp.svg'
import iconText from '../../assets/images/components/icon-text.svg'

const Items = [
  {
    relativePath: '/docs/alertas--intro',
    label: 'Alertas',
    icon: iconAlertas,
    style: { my: { lg: 6, xl: 6 } },
    description: 'Interrompem os usuário com informações, detalhes ou ações urgentes.',
  },
  {
    relativePath: '/docs/autocomplete--intro',
    label: 'Autocomplete',
    icon: iconAutoComplete,
    style: { my: { lg: 6, xl: 6 } },
    description: 'Autocomplete (auto completar) é um campo de texto com uma lista de opções para selecionar ou completar automaticamente.',
  },
  {
    relativePath: '/docs/breadcrumb--basic',
    label: 'Breadcrumbs',
    icon: iconBreadcrumbs,
    style: { my: { lg: 6, xl: 6 } },
    description: 'Breadcrumbs (migalhas de pão) é a exibição da localização da página atual dentro da hierarquia de navegação.',
  },
  {
    relativePath: '/docs/button--intro',
    label: 'Button',
    icon: iconButton,
    style: { my: { lg: 5, xl: 5 } },
    description: 'Button (botão) permite que o usuário realize ações e escolhas dentro do sistema.',
  },
  {
    relativePath: '/docs/card--intro-card',
    label: 'Card',
    icon: iconCard,
    style: { my: { lg: 5, xl: 5 } },
    description: 'Cards são superfícies que exibem conteúdo e ações em um único tópico.',
  },
  {
    icon: iconCollapse,
    relativePath: '/docs/collapse--collapse-intro',
    label: 'Collapse',
    style: { my: { lg: 5, xl: 5 } },
    description: 'Collapse (colapso) é um elemento expansível e recolhível.',
  },
  {
    icon: iconDatepicker,
    label: 'Date picker',
    style: { my: { lg: 9, xl: 7 } },
    description: 'O Date Piker (seletores de data) permitem que os usuários selecionem uma data ou intervalo de datas.',
  },
  {
    icon: iconFilter,
    label: 'Filter',
    style: { my: { lg: 9, xl: 7 } },
    description: 'Filter (filtro) é usado para diminuir a seleção de dados mostrados na visualização.',
  },
  {
    relativePath: '/docs/footer--intro',
    icon: iconFooter,
    label: 'Footer',
    style: { my: { lg: 9, xl: 7 } },
    description: 'Footer (rodapé) é o conteúdo complementar mostrado no inferior da página.',
  },
  {
    relativePath: '/docs/form--intro',
    icon: iconForm,
    label: 'Form',
    style: { my: { lg: 4, xl: 4 } },
    description: 'Form (formulários) é um grupo de informações relacionadas para que o usuário insira as informações.',
  },
  {
    relativePath: '/docs/header--intro',
    icon: iconHeader,
    label: 'Header',
    style: { my: { lg: 4, xl: 4 } },
    description: 'O Header (cabeçalho) é a principal forma de o usuário acessar cada parte de um produto.',
  },
  {
    icon: iconLink,
    relativePath: '/docs/link--link-intro',
    label: 'Link',
    style: { my: { lg: 4, xl: 4 } },
    description: 'Os links direcionam os usuário para uma página ou algum outro recurso.',
  },
  {
    relativePath: '/docs/login--intro-login',
    icon: iconLogin,
    label: 'Login',
    style: { my: { lg: 10, xl: 9 } },
    description: 'O login é o processo para acessar um sistema restrito feita através da autenticação.',
  },
  {
    icon: iconModal,
    relativePath: '/docs/modal--intro-modal',
    label: 'Modal',
    style: { my: { lg: 10, xl: 9 } },
    description: 'O modal informa os usuários sobre uma tarefa e podem conter informações críticas, exigir decisões ou envolver várias tarefas.',
  },
  {
    relativePath: '/docs/pagination--intro',
    icon: iconPagination,
    label: 'Pagination',
    style: { my: { lg: 10, xl: 9 } },
    description: 'Pagination (paginação) permite ao usuário selecionar uma página específica a partir de um intervalo de páginas.',
  },
  {
    icon: iconPassword,
    relativePath: '/docs/password-input--intro',
    label: 'Password input',
    style: { my: { lg: 6, xl: 6 } },
    description: 'Password input (entrada de texto para entrada de senha) seja para criação de conta ou login.',
  },
  {
    icon: iconProgress,
    label: 'Progress Indicator',
    style: { my: { lg: 6, xl: 6 } },
    description: 'Progress Indicator (indicadores de progresso) mostram o tempo de espera não especificado ou exibem a duração de um processo.',
  },
  {
    icon: iconSearch,
    label: 'Search',
    style: { my: { lg: 6, xl: 6 } },
    description: 'Search (pesquisar) permite que os usuários localizem o conteúdo do aplicativo rapidamente.',
  },
  {
    icon: iconSelection,
    relativePath: '/docs/selection-controls--intro',
    label: 'Selection controls',
    style: { my: { lg: 4, xl: 2 } },
    description: 'Selection controls (controle de seleção) permite que o usuário selecione um ou mais itens de um conjunto.',
  },
  {
    relativePath: '/docs/steppers--intro',
    label: 'Stepp',
    icon: iconStepp,
    style: { my: { lg: 4, xl: 2 } },
    description: 'Os steppers exibem o progresso por meio de uma sequência de etapas lógicas e numeradas.',
  },
  {
    relativePath: '/docs/text-input--intro',
    label: 'Text input',
    icon: iconText,
    style: { my: { lg: 4, xl: 2 } },
    description: 'O Text Input (campos de texto) permite que os usuários insiram e editem texto.',
  },
]

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
  },
  boxContainer: {
    textAlign: 'center',
    paddingLeft: 175,
    paddingRight: 175,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '90%',
      backgroundPosition: '55px 70px',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  card: {
    width: 105,
    height: 105,
    display: 'flex',
    position: 'relative',
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F5F5',
    paddingTop: 15,
    margin: 'auto',
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'not-allowed',
    '& img': {
      opacity: 0.3,
    },
  },
}))

const Components = () => {
  const classes = useStyles()
  const history = useHistory()

  /**
   * Temporary solution to disable access to components.
   */
  useEffect(() => history.push('/'), [history])

  return (
    <Master>
      <>
        <Banner image={imgBanner}>
          <Box>Guia de componentes</Box>
        </Banner>

        <Container maxWidth="lg">
          <Box className={classes.boxContainer}>
            <Box mt={5}>
              <Box pt={6}>
                <Typography variant="h4">
                  Components
                </Typography>
              </Box>
            </Box>

            <Box mt={7} mb={15} px={{ lg: 5 }}>
              <Typography variant="body1">
                Os components (componentes) são a coleção de elementos de interface
                que podem ser reutilizados dentro dos sistemas Pottencial.
              </Typography>
            </Box>

            <Box mb={0}>
              <Grid container spacing={10}>
                {Items.map((item, index) => (
                  <Grid item md={4} xs={12} key={index}>
                    <Box {...item.style}>
                      <Card
                        component="a"
                        target="_blank"
                        title={item.label}
                        className={`${classes.card} ${!item.relativePath && classes.disabled}`}
                        href={item.relativePath && `${window.ENV.URL_STORYBOOK}?path=${item.relativePath}`}
                      >
                        <CardContent>
                          <Box className="buttonCard">
                            <img src={item.icon} alt={item.label} />
                          </Box>
                        </CardContent>
                      </Card>

                      <Box>
                        <Box mb={2}>
                          <Typography variant="h6">{item.label}</Typography>
                        </Box>

                        <Box>
                          <Typography variant="body2">{item.description}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </>
    </Master>
  )
}

export default Components
