import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import Master from '../../layouts/Master'
import Banner from '../../layouts/Banner'

import imgBanner from '../../assets/images/guidelines/bg-guidelines.png'
import iconEmail from '../../assets/images/guidelines/icon-email.svg'
import iconTomVoz from '../../assets/images/guidelines/icon-tom-voz.svg'
import iconDocuments from '../../assets/images/guidelines/icon-documents.svg'
import background from '../../assets/images/guidelines/bg-lines-guidelines.svg'

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
  },
  boxContainer: {
    textAlign: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xl')]: {
      backgroundSize: '80%',
      backgroundPosition: '53% 0%',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0%',
    },
  },
  card: {
    width: 105,
    height: 105,
    display: 'flex',
    position: 'relative',
    marginBottom: 25,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F5F5F5',
    paddingTop: 15,
    margin: 'auto',
    cursor: 'pointer',
  },
  gridCardText: {
    width: 300,
    position: 'relative',
    margin: 'auto',
  },
  gridEmail: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
    marginTop: 100,
  },
  gridDocuments: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      left: 60,
    },
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  gridTomVoz: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      right: 65,
    },
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    '& img': {
      opacity: 0.5,
    },
  },
}))

const Guidelines = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Master>
      <>
        <Banner image={imgBanner}>
          <Box>Guidelines</Box>
        </Banner>

        <Container maxWidth="lg">
          <Box className={classes.boxContainer}>
            <Box pt={10} ml={2}>
              <Typography variant="h4" className={classes.title}>Guidelines</Typography>
            </Box>

            <Box mt={7} mb={10} px={{ lg: 20 }}>
              <Typography variant="body1">
                As Guidelines (diretrizes) descreve um processo padronizado, onde estão
                definidas as regras e condições para construção de um artefato em um ecossistema.
              </Typography>
            </Box>

            <Box mb={5} px={10}>
              <Grid container spacing={10}>
                <Grid xs={12} md={4} className={classes.gridDocuments} item title="Documents">
                  <Card className={`${classes.card} ${classes.disabled}`}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={iconDocuments} alt="Documents" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Box mb={2}>
                      <Typography variant="h6" className={classes.title}>Documents</Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        Os documentos com um layout padrão trás mais agilidade e facilidade de
                        leitura para os clientes e os colaboradores da Pottencial. Eles são
                        adaptáveis de acordo com o conteúdo de cada situação.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} md={4} item title="E-mail">
                  <Box className={classes.gridEmail}>
                    <Card className={classes.card} onClick={() => history.push('/email')}>
                      <CardContent>
                        <Box className="buttonCard">
                          <img src={iconEmail} alt="E-mail" />
                        </Box>
                      </CardContent>
                    </Card>

                    <Box className={classes.gridCardText}>
                      <Box mb={2}>
                        <Typography variant="h6" className={classes.title}>E-mail</Typography>
                      </Box>

                      <Box>
                        <Typography variant="body2">
                          E-mail é o conteúdo enviado aos clientes informando sobre
                          alguma situação específica.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} md={4} className={classes.gridTomVoz} item title="Tom de voz">
                  <Card className={classes.card} onClick={() => history.push('/voice')}>
                    <CardContent>
                      <Box className="buttonCard">
                        <img src={iconTomVoz} alt="Tom de voz" />
                      </Box>
                    </CardContent>
                  </Card>

                  <Box className={classes.gridCardText}>
                    <Box mb={2}>
                      <Typography variant="h6" className={classes.title}>Tom de voz</Typography>
                    </Box>

                    <Box>
                      <Typography variant="body2">
                        O Tom de Voz é a expressão da personalidade da Pottencial na comunicação
                        com cliente e parceiros. Precisa ser consistente e estar alinhados com
                        o propósito da empresa.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </>
    </Master>
  )
}

export default Guidelines
