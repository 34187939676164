import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Header from '../components/Header'
import Footer from '../components/Footer'

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: 63.4999,
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% - 240px)',
      marginLeft: 240,
    },
  },
  drawerPaper: {
    zIndex: 1,
    paddingTop: 100,
    width: 240,
  },
  active: {
    fontWeight: 600,
    color: '#000000',
    borderLeft: 3,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
}))

const Master = ({ children, pathsMenu }) => {
  const classes = useStyles()

  return (
    <>
      <Header menuWhite />
      <main className={pathsMenu.length !== 0 ? classes.main : ''}>
        {children}
      </main>
      <Footer />
      {pathsMenu.length !== 0 && (
        <nav>
          <Hidden only={['xs', 'sm', 'md']}>
            <Drawer
              open
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <List>
                {pathsMenu.map((path, index) => (
                  <Fragment key={index}>
                    {path.href && (
                      <ListItem
                        button
                        to={path.href}
                        title={path.text}
                        component={NavLink}
                        activeClassName={classes.active}
                      >
                        <ListItemText primary={path.text} />
                      </ListItem>
                    )}

                    {!path.href && (
                      <ListItem
                        title={path.text}
                        className={classes.disabled}
                      >
                        <ListItemText primary={path.text} />
                      </ListItem>
                    )}
                  </Fragment>
                ))}
              </List>
            </Drawer>
          </Hidden>
        </nav>
      )}
    </>
  )
}

Master.propTypes = {
  pathsMenu: PropTypes.array,
  children: PropTypes.element.isRequired,
}

Master.defaultProps = {
  pathsMenu: [],
}

export default Master
