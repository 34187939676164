import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import Master from '../../layouts/Master'
import { STYLE_MENU } from '../../constants'
import NavFooter from '../../layouts/NavFooter'

import logoMarca from '../../assets/images/logo/logo-marca.svg'
import logo40mm from '../../assets/images/logo/logo-40mm.svg'
import logo30mm from '../../assets/images/logo/logo-30mm.svg'
import logo20mm from '../../assets/images/logo/logo-20mm.svg'
import logo10mm from '../../assets/images/logo/logo-10mm.svg'
import logoVariacao1 from '../../assets/images/logo/logo-variacao-1.svg'
import logoVariacao2 from '../../assets/images/logo/logo-variacao-2.svg'
import logoErrada1 from '../../assets/images/logo/logo-errada-1.svg'
import logoErrada2 from '../../assets/images/logo/logo-errada-2.svg'

import iconPng from '../../assets/images/logo/iconPng.png'
import iconSvg from '../../assets/images/logo/iconSvg.png'

import logoPng from '../../assets/images/logo.png'
import logoSvg from '../../assets/images/logo.svg'

const useStyles = makeStyles((theme) => ({
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  img: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
  },
  imgLogoPottencial: {
    width: '35%',
    display: 'flex',
    margin: 'auto',
    position: 'relative',
  },
  imgBoasPraticas: {
    width: '30%',
    display: 'flex',
    margin: 'auto',
    position: 'relative',
  },
  txtUtilizarLogo: {
    textAlign: 'center',
    paddingTop: 110,
    float: 'right',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 30,
    },
  },
  imgUtilizarLogo: {
    width: '45%',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      paddingBottom: 10,
      margin: 'auto',
      float: 'none',
    },
  },
  iconButton: {
    width: 200,
    height: 60,
    borderRadius: 5,
    boxShadow: '0 0',
    display: 'block',
    textDecoration: 'none',
    border: '1px solid #e4e4e4',
  },
  iconImageButtom: {
    width: '60%',
    float: 'left',
    position: 'relative',
    margin: 'auto',
  },
  iconBox: {
    background: '#e2e3e5',
    width: 60,
    height: 60,
    float: 'left',
    display: 'flex',
  },
  iconText: {
    position: 'relative',
    left: 12,
    bottom: 5,
  },
  boxJpg: {
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  boxSvg: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  box40mm: {
    textAlign: 'center',
  },
  box30mm: {
    textAlign: 'center',
    position: 'relative',
    right: 40,
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  box20mm: {
    textAlign: 'center',
    position: 'relative',
    right: 90,
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  box10mm: {
    textAlign: 'center',
    position: 'relative',
    right: 135,
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
}))

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Style',
    href: '/styles',
  },
  {
    text: 'Logo Pottencial',
  },
]

const Logo = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Box>
            <Typography variant="h4">
              Logo Pottencial
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              A marca, juntamente com os elementos do Sistema de Identidade Visual,
              é um dos meios pelos quais uma empresa se comunica com o mundo.
              A marca Pottencial Seguradora é formada pelo símbolo, pelo logotipo
              e pelo ramo de atividade.
            </Typography>
          </Box>

          <Box mb={5}>
            <img src={logoMarca} className={classes.imgLogoPottencial} alt="Logo Pottencial" />
          </Box>

          <Box>
            <Typography variant="h4">
              Boas práticas de utilização da logo
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              Para garantir a leitura da marca, estabeleceu-se o seu limite de redução.
              Determinou-se que a redução máxima da marca é de 20 mm de largura.
            </Typography>
          </Box>

          <Box>
            <img src={logo40mm} className={classes.imgBoasPraticas} alt="40mm" />
          </Box>
          <Box>
            <img src={logo30mm} className={classes.imgBoasPraticas} alt="30mm" />
          </Box>
          <Box>
            <img src={logo20mm} className={classes.imgBoasPraticas} alt="20mm" />
          </Box>
          <Box mb={5}>
            <img src={logo10mm} className={classes.imgBoasPraticas} alt="10mm" />
          </Box>

          <Box>
            <Typography variant="h4">
              Variações que podem ser utilizadas
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box>
            <img src={logoVariacao1} className={classes.imgUtilizarLogo} alt="Bordas levemente arredondadas." />
            <Typography className={classes.txtUtilizarLogo} variant="subtitle1">
              Bordas levemente arredondadas.
            </Typography>
          </Box>

          <Box>
            <img src={logoVariacao2} className={classes.imgUtilizarLogo} alt="Sem o box laranja no fundo mas mantendo a cor laranja na logo e ícone." />
            <Typography className={classes.txtUtilizarLogo} variant="subtitle1">
              Sem o box laranja no fundo mas mantendo a cor laranja na logo e ícone.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4">
              Como não utilizar a logo Pottencial
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box>
            <img src={logoErrada1} className={classes.imgUtilizarLogo} alt="Não utilizar como marca d’ água!" />
            <Typography className={classes.txtUtilizarLogo} variant="subtitle1">
              Não utilizar como marca d’ água!
            </Typography>
          </Box>

          <Box>
            <img src={logoErrada2} className={classes.imgUtilizarLogo} alt="Não utilizar a marca em linhas!" />
            <Typography className={classes.txtUtilizarLogo} variant="subtitle1">
              Não utilizar a marca em linhas!
            </Typography>
          </Box>

          <Box>
            <Typography variant="h4">
              Download da logo Pottencial
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              A logo oficial da Potencial está disponível para download
              em duas opções, PNG e SVG:
            </Typography>
          </Box>

          <Box mb={5} className={classes.boxJpg}>
            <Card
              component="a"
              href={logoPng}
              download="Logo PNG"
              className={classes.iconButton}
            >
              <Box className={classes.iconBox}>
                <img src={iconPng} className={classes.iconImageButtom} alt="PNG" />
              </Box>
              <CardContent className={classes.iconText}>
                <Typography style={{ fontWeight: 900 }} variant="body2">
                  Download Logo
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  PNG
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box mb={5} className={classes.boxSvg}>
            <Card
              component="a"
              href={logoSvg}
              download="Logo SVG"
              className={classes.iconButton}
            >
              <Box className={classes.iconBox}>
                <img src={iconSvg} className={classes.iconImageButtom} alt="SVG" />
              </Box>
              <CardContent className={classes.iconText}>
                <Typography style={{ fontWeight: 900 }} variant="body2">
                  Download Logo
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  SVG
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Divider />

          <NavFooter
            previewLabel="Icons"
            previewTo="/icons"
          />
        </Box>
      </Container>
    </Master>
  )
}

export default Logo
