import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import Divider from '@material-ui/core/Divider'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'

import Master from '../../layouts/Master'
import { STYLE_MENU } from '../../constants'
import NavFooter from '../../layouts/NavFooter'

import typographyPageImg01 from '../../assets/images/typography/img-example-01.png'
import typographyPageImg02 from '../../assets/images/typography/img-example-02.png'

const useStyles = makeStyles((theme) => ({
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  link: {
    fontSize: 15,
    fontWeight: 700,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  img: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
  },
  boxFontes: {
    width: '100%',
    border: '1px solid black',
    marginBottom: 15,
  },
  fontOpenSans: {
    textAlign: 'right',
    padding: 50,
    left: '15%',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
    '& h1': {
      fontWeight: 700,
      fontFamily: 'Open Sans',
    },
    '& h5': {
      fontWeight: 100,
      fontFamily: 'Open Sans',
      marginBottom: 10,
    },
  },
  fontPoppins: {
    padding: 50,
    right: '15%',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
    '& h1': {
      fontWeight: 700,
      fontFamily: 'Poppins',
    },
    '& h5': {
      fontWeight: 100,
      fontFamily: 'Poppins',
      marginBottom: 10,
    },
  },
  boxTamanhos: {
    width: '100%',
    border: '2px dashed black',
    borderRadius: 5,
    marginBottom: 15,
  },
  tableRow: {
    '& th': {
      fontWeight: 600,
      fontSize: 'large',
      borderBottom: 0,
    },
  },
  tableRowSub: {
    '& th': {
      fontWeight: 100,
      fontSize: 'medium',
      borderBottom: 0,
    },
    '& .titleTh': {
      fontWeight: 600,
      fontSize: 'large',
      borderBottom: 0,
    },
  },
}))

const MULTIPLOS_8 = 'Múltiplos 8'
const BOLD_REGULAR = 'Bold, Regular'
const SEMIBOLD_REGULAR = 'Semibold, Regular'

const createData = (elemento, fonte, peso, tamanhoRem, tamanhoPx, letterspace) => ({
  elemento, fonte, peso, tamanhoRem, tamanhoPx, letterspace,
})

const Tamanhos = [
  createData('H1', 'Poppins', BOLD_REGULAR, '3.5', '96', MULTIPLOS_8),
  createData('H2', 'Poppins', BOLD_REGULAR, '2.375', '38', MULTIPLOS_8),
  createData('H3', 'Poppins', BOLD_REGULAR, '2', '32', MULTIPLOS_8),
  createData('H4', 'Poppins', BOLD_REGULAR, '1.562', '25', MULTIPLOS_8),
  createData('H5', 'Poppins', SEMIBOLD_REGULAR, '1.25', '20', MULTIPLOS_8),
  createData('H6', 'Poppins', SEMIBOLD_REGULAR, '1.125', '18', MULTIPLOS_8),
  createData('Subtitle 1', 'Poppins', SEMIBOLD_REGULAR, '1', '16', '0px'),
  createData('Subtitle 2', 'Poppins', SEMIBOLD_REGULAR, '0.875', '14', '0px'),
  createData('Overline', 'Open Sans', 'Regular', '0.75', '12', '0px'),
  createData('Body 1', 'Open Sans', 'Bold, Semibold, Regular', '1', '16', '0px'),
  createData('Body 2', 'Open Sans', 'Bold, Semibold, Regular', '0.75', '12', '0px'),
  createData('Button', 'Open Sans', 'Semibold', '0.875', '14', '0px'),
  createData('Caption', 'Open Sans', 'Regular', '0.75', '12', '0px'),
]

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Style',
    href: '/styles',
  },
  {
    text: 'Tipografia',
  },
]

const TypographyPage = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Box>
            <Typography variant="h4">
              Typography
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              A tipografia é um estudo que nos permite fazer a escolha de fontes para nossos
              produtos. Vários são os critérios avaliados para a escolha de
              <br />
              fontes. Leva-se em conta o contexto de uso, os dispositivos onde serão
              projetadas e até mesmo o contexto do negócio.
              <br />
              <br />
              Um desses critérios é o da
              {' '}
              <b>legibilidade</b>
              , sendo um critério de extrema relevância quando entendemos que são
              textos que devem ser lidos
              <br />
              com facilidade e comunicar seu propósito de forma eficiente.
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="h5">
              Fontes
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              As fontes utilizadas em nossos produtos são Open Sans e Poppins.
              Ambas são fontes gratuitas e podem ser
              baixadas através do
              {' '}
              <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer" className={classes.link}>Google Fonts</a>
              {' '}
              .
            </Typography>
          </Box>

          <Box className={classes.boxFontes}>
            <Grid container>
              <Grid xs={12} md={6} className={classes.fontOpenSans} item>
                <Typography variant="h1">Aa</Typography>
                <Typography variant="h5">Open Sans</Typography>
                <a href="https://fonts.google.com/download?family=Open%20Sans" target="_blank" rel="noopener noreferrer" className={classes.link}>Baixar fonte</a>
              </Grid>
              <Grid xs={12} md={6} className={classes.fontPoppins} item>
                <Typography variant="h1">Aa</Typography>
                <Typography variant="h5">Poppins</Typography>
                <a href="https://fonts.google.com/download?family=Poppins" target="_blank" rel="noopener noreferrer" className={classes.link}>Baixar fonte</a>
              </Grid>
            </Grid>
          </Box>

          <Box mb={5} mt={5}>
            <Typography variant="h5">
              Tamanhos
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              O sistema de tamanhos segue a medida
              {' '}
              <b>rem</b>
              {' '}
              (relativa ao tamanho de elemento raiz) que por padrão em navegadores é de 16px.
              Os tamanhos das fontes podem ser responsivos conforme o tamanho da tela.
            </Typography>
          </Box>

          <Box className={classes.boxTamanhos}>
            <Grid container>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell>Elemento</TableCell>
                      <TableCell>Fonte</TableCell>
                      <TableCell>Peso</TableCell>
                      <TableCell align="center" colSpan={2}>Tamanho</TableCell>
                      <TableCell>Letterspace</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRowSub}>
                      <TableCell colSpan={3} />
                      <TableCell align="center">(rem)</TableCell>
                      <TableCell align="center">(px)</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Tamanhos.map((tamanho, index) => (
                      <TableRow key={index} className={classes.tableRowSub}>
                        <TableCell className="titleTh" component="th" scope="row">
                          {tamanho.elemento}
                        </TableCell>
                        <TableCell component="th">{tamanho.fonte}</TableCell>
                        <TableCell component="th">{tamanho.peso}</TableCell>
                        <TableCell component="th" align="center">{tamanho.tamanhoRem}</TableCell>
                        <TableCell component="th" align="center">{tamanho.tamanhoPx}</TableCell>
                        <TableCell component="th">{tamanho.letterspace}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>

          <Box mt={5}>
            <Typography variant="h4">
              Anatomia
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              Utilização dos elementos de tipografia na tela de login do portal do cliente.
            </Typography>
          </Box>

          <Box mb={5} width="60%">
            <img className={classes.img} src={typographyPageImg01} alt="Anatomia" />
          </Box>

          <Box mb={5}>
            <Typography variant="body1">
              1. H5
              <br />
              2. Body 2
              <br />
              3. Button
            </Typography>
          </Box>

          <Box mt={5}>
            <Typography variant="h4">
              Exemplo de aplicação
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <img className={classes.img} src={typographyPageImg02} alt="Exemplo de aplicação" />
          </Box>

          <Divider />

          <NavFooter
            nextLabel="Palette Colours"
            nextTo="/palette-colours"
          />
        </Box>
      </Container>
    </Master>
  )
}

export default TypographyPage
