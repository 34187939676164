import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { ErrorBoundary } from './components'

const Main = () => (
  <>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </>
)

ReactDOM.render(
  <Main />,
  document.getElementById('root'),
)
