import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Master from '../../layouts/Master'
import NavFooter from '../../layouts/NavFooter'
import { STYLE_MENU_GUIDELINES } from '../../constants'

import iconHtml from '../../assets/images/email/iconHtml.png'
import emailTemplate from '../../assets/images/email/email-template.txt'

import pageEmailExemplo01 from '../../assets/images/email/img-example-01.png'
import pageEmailExemplo02 from '../../assets/images/email/img-example-02.png'

const useStyles = makeStyles(() => ({
  line: {
    background: '#F26522',
    width: '10%',
    height: 2,
    marginTop: 30,
    marginBottom: 30,
  },
  img: {
    width: '80%',
    display: 'flex',
    margin: 'auto',
  },
  xdButtom: {
    width: 200,
    height: 60,
    borderRadius: 5,
    boxShadow: '0 0',
    border: '1px solid #e4e4e4',
  },
  xdImage: {
    width: '35%',
    float: 'left',
    display: 'flex',
    position: 'relative',
    bottom: 5,
    background: '#919191',
  },
  xdText: {
    position: 'relative',
    left: 12,
    bottom: 5,
  },
  gridCard: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCardText: {
    fontSize: 12,
  },
  imgIcon: {
    maxWidth: 60,
  },
  iconImage: {
    height: 50,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    width: 200,
    height: 60,
    borderRadius: 5,
    boxShadow: '0 0',
    display: 'block',
    textDecoration: 'none',
    border: '1px solid #e4e4e4',
  },
  iconBox: {
    background: '#e2e3e5',
    width: 60,
    height: 60,
    float: 'left',
    display: 'flex',
  },
  iconImageButton: {
    width: '80%',
    float: 'left',
    position: 'relative',
    margin: 'auto',
  },
  iconText: {
    position: 'relative',
    left: 12,
    bottom: 5,
  },
}))

const Paths = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Guidelines',
    href: '/guidelines',
  },
  {
    text: 'Email',
  },
]

const Email = () => {
  const classes = useStyles()

  return (
    <Master
      pathsCrumb={Paths}
      pathsMenu={[...STYLE_MENU_GUIDELINES]}
    >
      <Container maxWidth="lg">
        <Box pt={10}>
          <Typography component="h1" variant="h4">
            E-mail
          </Typography>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography variant="body1">
              O e-mail é um dos principais canais de comunicação entre o usuário e nossos
              sistemas. Foi criada uma estrutura de layout para padronizar e tornar
              eficiente nossa comunicação com o cliente Pottencial. Os e-mails devem
              contemplar um tom de voz específico para cenários diferentes dentro do
              contexto do produto.
            </Typography>
          </Box>

          <Box mb={5}>
            <Card
              component="a"
              href={emailTemplate}
              download="Modelo e-mail.html"
              className={classes.iconButton}
            >
              <Box className={classes.iconBox}>
                <img src={iconHtml} className={classes.iconImageButton} alt="Modelo e-mail" />
              </Box>
              <CardContent className={classes.iconText}>
                <Typography style={{ fontWeight: 900 }} variant="body2">
                  Download modelo
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  HTML
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box>
            <Typography component="h2" variant="h4">
              Anatomia
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography component="h3" variant="h5">
              E-mail
            </Typography>
          </Box>

          <Box mb={5} width="75%">
            <img className={classes.img} src={pageEmailExemplo01} alt="Anatomia" />
          </Box>

          <Box mb={5}>
            <Typography variant="body2">
              1. Logo Pottencial e nome do produto (obrigatório)
              {' '}
              <br />
              2. Mensagem principal, assunto do e-mail
              {' '}
              <br />
              3. Comprimento, mensagem de saudação
              {' '}
              <br />
              4. Ilustração ou imagem que componha o tom e o objetivo do email (opcional)
              {' '}
              <br />
              5. Corpo do email
              {' '}
              <br />
              6. Botão de ação (hiperlink)
              {' '}
              <br />
              7. Footer
            </Typography>
          </Box>

          <Box>
            <Typography component="h3" variant="h4">
              Exemplo de aplicação
            </Typography>
          </Box>

          <Box className={classes.line} />

          <Box mb={5}>
            <Typography component="h3" variant="h5">
              Definição de senha - Espaço Corretor
            </Typography>
          </Box>

          <Box mb={5} width="70%">
            <img className={classes.img} src={pageEmailExemplo02} alt="Exemplo de aplicação" />
          </Box>

          <Divider />

          <NavFooter
            previewLabel="Documento"
            nextLabel="Tom de voz"
            nextTo="/voice"
          />

        </Box>
      </Container>
    </Master>
  )
}

export default Email
